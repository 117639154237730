<template>
  <div class="student-detail-registration">
    <v-container>
      <div class="main-container">
        <v-card
          rounded="lg"
          class="pa-9"
          :class="{'py-9 px-4': $vuetify.breakpoint.smAndDown}"
        >
          <h2>{{ 'registration.detail.title' | text }}</h2>
          <div class="student-detail-registration__status mt-4">
            <v-row 
              no-gutters
              v-for="item in getStatus"
              :key="item.title"
            >
              <v-col
                cols="3"
                sm="3"
                md="3"
                lg="2"
              >
                <p>{{ item.title | text }}</p>
              </v-col>
              <v-col
                cols="9"
                sm="9"
                md="9"
                lg="10"
              >
                <p>{{ `: ${item.value}` }}</p>
              </v-col>
            </v-row>
          </div>
          <v-divider></v-divider>
          <v-alert
            dismissible
            type="error"
            class="mt-4"
            v-model="isShowAlert"
          >{{ alertMsg }}</v-alert>
          <div class="mt-4">
            <p class="title-14 mb-2">Gelombang Pendaftar</p>
            <v-select
              v-model="universityIntake"
              :items="universityIntakes"
              :disabled="isHideButton"
              dense
              outlined
            ></v-select>
          </div>
          <p class="title-14 mb-2">Tahap Pendaftaran</p>
          <stepper :prodi="programStudy" :period="period" source="dashboard"/>
          <div v-if="!isHideButton" class="d-flex justify-end">
            <v-btn 
              color="primary"
              :min-width="120"
              outlined
              large
              class="mr-2"
              @click="submitForm"
            >
              KIRIM
            </v-btn>
          </div>
        </v-card>
      </div>
    </v-container>
    <dialog-info ref="dialogInfoSuccess" :info="infoSuccess" :action="handleBack"/>
    <dialog-confirm ref="dialogConfirmSubmitForm" :info="info" :action="handleOnSubmit"/>
  </div>
</template>

<script>
// Libs 
import { get } from 'dot-prop'

// Components
import stepper from '@/components/stepper'
import DialogConfirm from '@/components/common/DialogConfirm'
import DialogInfo from '@/components/common/DialogInfo'

// Constants
import { text } from '@/apps/web/constants/text'
import { DETAIL, STUDENT_DETAIL_FORM, STUDENT_DETAIL_SHEET, STUDENT_DETAIL_PAYMENT } from '@/constants/pages'
import { API_DASH_FORM_SUBMIT } from '@/constants/apis'
import {
  INIT,
  FORM_PENDING,
  FORM_COMPLETE,
  VALIDATE_PENDING,
  VALIDATE_COMPLETE,
  PASSED,
  STUDENT_AGREE,
  ACCEPTED,
  REJECTED,
  ALUMNI,
  DROPOUT
} from '@/constants/status'

// Utils
import loadscreen from '@/utils/common/loadScreen'
import request from '@/utils/request'
import store from '@/store'

  export default {
    name: 'DashDetailRegistration',
    components: {
      stepper,
      DialogConfirm,
      DialogInfo
    },
    data () {
      return {
        universityIntake: '',
        alertMsg: '',
        isShowAlert: false,
        programStudy: '',
        prodiTitle: '',
        period: '',
        info: {
          desc: 'Anda yakin data yang akan anda kirim sudah benar?'
        },
        infoSuccess: {
          title: 'dialog.success',
          desc: 'dialog.success.submit.form',
          btn: 'dialog.success.btn'
        },
      }
    },
    watch: {
      universityIntake() {
        this.$store.dispatch('setSelectedAcademicYear', this.universityIntake)
      }
    },
    computed: {
      getStatus() {
        return [{
          title: 'registration.detail.status.program',
          value: this.prodiTitle
        }, {
          title: 'registration.detail.status.periode',
          value: this.period
        }]
      },
      universityIntakes() {
        const academicYear = get(this.$store.state, 'dashboard.dashAcademicYear', [])
        const list = []
        academicYear.map(itm => {
          list.push(itm.title)
        })
        return list
      },
      getUniversityIntake () {
        const academicYear = get(this.$store.state, 'dashboard.dashAcademicYear', [])
        const id = get(this.$store.state, 'academic_year_id', '')
        let title = this.universityIntakes[0]
        academicYear.map(itm => {
          if (itm.id === id) {
            title = itm.title
            return
          }
        })
        return title
      },
      isDisabled () {
        const completionForm = get(this.$store.state, 'completionForm', {})
        const checkCompletion = {
          is_complete_file: completionForm.is_complete_file || false,
          is_financial_support_form: completionForm.is_financial_support_form || false,
          is_health_form: completionForm.is_health_form || false,
          is_receipt_payment: completionForm.is_receipt_payment || false,
          is_recommendation_form: completionForm.is_recommendation_form || false,
          is_registration_form: completionForm.is_registration_form || false,
          is_motivation_form: completionForm.is_motivation_form || false
        }
        if (['sth', 'mdiv'].indexOf(this.programStudy) !== -1) {
          checkCompletion.is_faith_calling_form = completionForm.is_faith_calling_form || false
          checkCompletion.is_repentance_testimony_form = completionForm.is_repentance_testimony_form || false
        }
        if (['mmin', 'sert'].indexOf(this.programStudy) !== -1) {
          checkCompletion.is_repentance_testimony_form = completionForm.is_repentance_testimony_form || false
        }
        const disabledData = []
        Object.keys(checkCompletion).map(itm => {
          if (!checkCompletion[itm]) {
            disabledData.push(itm)
          }
        })
        return disabledData.length > 0
      },
      isHideButton () {
        const status = get(this.$store.state, 'status', 'init')
        return [INIT, ACCEPTED, REJECTED, ALUMNI, DROPOUT].indexOf(status) !== -1
      }
    },
    beforeRouteEnter (to, from, next) {
      const completionForm = get(store.state, 'completionForm', {})
      if (!completionForm.is_registration_form || [STUDENT_DETAIL_FORM,STUDENT_DETAIL_SHEET,STUDENT_DETAIL_PAYMENT ].indexOf(from.name) !== -1 ) {
        const params = {
          entry_form_id: to.query.formId || null,
        }
        store.dispatch('getDashboardCompletionForm', params).then(res => {
          if (!res.success) {
            next({
              name: DETAIL,
              query: {
                formId: params.entry_form_id
              }
            })
            return
          }
          next()
        })
      } else {
        next()
      }
      
    },
    async created () {
      loadscreen.show()
      const params = {
        entry_form_id: this.$route.query.formId || null,
        program_study: this.$route.query.prodi || null,
      }
      const res = await this.$store.dispatch('dashboard/getDashAcademicYear')
      if (res.success) {
        this.programStudy = params.program_study
        this.prodiTitle = text[`program.studi.${this.programStudy}.title`]
        this.period = this.$route.query.periode || new Date().getFullYear()
        this.universityIntake = this.getUniversityIntake
      } else {
        this.$router.replace({
          name: DETAIL,
          query: {
            formId: params.entry_form_id
          }
        }).catch(() => {})
      }
      loadscreen.hide()
    },
    methods: {
      submitForm () {
        this.$refs.dialogConfirmSubmitForm && this.$refs.dialogConfirmSubmitForm.show()
      },
      handleBack () {
        this.$router.push({
          name: DETAIL,
          query: {
            formId: this.$route.query.formId || null,
          }
        }).catch(() => {})
      },
      handleOnSubmit (type = 'submit') {
        loadscreen.show()
        const academicYearList = this.$store.state.dashboard.dashAcademicYear
        const academicYear = this.$store.state.selectedAcademicYear
        let academicYearId = ''
        academicYearList.map(itm => {
          if (itm.title === academicYear) {
            academicYearId = itm.id
          }
        })
        const params = {
          entry_form_id: this.$route.query.formId || null,
          academic_year_id: academicYearId,
        }
        if (type !== 'submit') {
          params.is_save_to_draft = true
        }
        request(API_DASH_FORM_SUBMIT, params).then(res => {
          if (res.success) {
            type === 'submit' ? this.$refs.dialogInfoSuccess && this.$refs.dialogInfoSuccess.show() : ''
          } else {
            this.alertMsg = get(res, 'resultInfo.resultMsg', '')
            this.isShowAlert = true
          }
        })
        loadscreen.hide()
      }
    }
  }
</script>

<style lang="scss" scoped>
.student-detail-registration {
  font-size: 14px;
  .title-14 {
    font-weight: bold;
    font-size: 14px;
  }
}
</style>