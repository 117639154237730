<template>
  <v-stepper
    v-model="activeStep"
    elevation="0"
    vertical
    class="stepper"
  >
    <template
      v-for="(step, index) in stepper"
    >
      <v-stepper-step
        :key="step.id + index"
        :complete="step.isComplete"
        :step="step.id"
        @click="handleStep(step.id)"
      >
        <p class="stepper-title">{{ step.title }}</p>
      </v-stepper-step>

      <v-stepper-content :step="step.id" :key="index">
        <v-btn
          color="primary"
          :min-width="160"
          large
          @click="goToFormPage(step)"
        >
          Lengkapi Berkas
        </v-btn>
      </v-stepper-content>
    </template>
  </v-stepper>
</template>

<script>
import {
  STH,
  MTH,
  MMIN,
  MDIV,
  SERT
} from '@/constants/programStudy'
  export default {
    data () {
      return {
        activeStep: 1,
      }
    },
    props: {
      prodi: {
        type: String,
        default: 'sth'
      },
      period: {
        type: String,
        default: new Date().getFullYear()
      },
      source: {
        type: String,
        default: 'student'
      }
    },
    computed: {
      stepper () {
        let list = []
        if (this.prodi === 'sth') {
          list = STH
        } else if (this.prodi === 'mth') {
          list = MTH
        } else if (this.prodi === 'mthu') {
          list = STH
        } else if (this.prodi === 'mmin') {
          list = MMIN
        } else if (this.prodi === 'mdiv') {
          list = MDIV
        } else if (this.prodi === 'sert') {
          list = SERT
        }
        list.map(itm => {
          itm.isComplete = this.isFormCompletion[itm.fields] || false
        })
        return list
      },
      isFormCompletion () {
        return this.$store.state.completionForm
      }
    },
    methods: {
      handleStep (id) {
        this.activeStep = id
      },
      goToFormPage (step) {
        const formSheet = [
          'is_repentance_testimony_form', 
          'is_faith_calling_form',
          'is_motivation_form',
          'is_couple_agreement_form',
          'is_health_form',
          'is_financial_support_form',
          'is_recommendation_form',
          'is_complete_file'
        ]
        let query = {
          prodi: this.prodi,
          periode: this.period,
          formId: this.$route.query.formId
        }
        if (formSheet.indexOf(step.fields) !== -1) {
          query.tipe = step.fields
        }
        this.$router.push({
          name: this.source === 'student' ? step.pathName[0] : step.pathName[1],
          query
        }).catch(() => {})
      }
    }
  }
</script>

<style lang="scss" scoped>
.stepper {
  &-title {
    font-weight: bold;
    font-size: 14px;
    margin: 0;
    color: black;
  }
}

</style>